import { FloatButton, Layout, notification } from 'antd';
import './HomeView.css';
import { useEffect, useRef, useState } from 'react';
import MainHeader from '../../components/header/MainHeader';
import LeftSidebar from '../../components/left-sidebar/LeftSideBar';
import MainZone from '../../components/main-zone/MainZone';
import kc from '../../util/Keycloak';
import { GlobalContext } from '../../contexts/GlobalContext';
import { CrownFilled, UploadOutlined } from '@ant-design/icons';
import Analytics from '../../analytics/analytics';

function HomeView() {
  const myRef = useRef(null);

  const [keycloak, setKeycloak] = useState(null);
  const [user, setUser] = useState(null);

  const [api, contextHolder] = notification.useNotification();

  useEffect(()=>{
    kc.init({ onLoad: 'check-sso' }).then((authenticated) => {
      console.log("authenticated: ", authenticated);
      setKeycloak(kc);
      setUser(kc.idTokenParsed)
    });
  }, [])

  const onUpload = () => {
    
    if(keycloak.authenticated /* && AUTHORIZED?  TODO*/){ 
      //Show Premium message
      Analytics.trackUploadFile("Unauthorized")
      api.info({
        message: 'Upload documents',
        description: 'You have to be a premium user to be able to upload your own documents',
        placement: "top",
        icon: <CrownFilled style={{color: "orange"}} />
      });
    }
  }

  return (
    <GlobalContext.Provider value={{keycloak, user}}>
      {contextHolder}
      <div className='App'>
        <MainHeader />
        <Layout hasSider>
          <LeftSidebar />
          <MainZone />
        </Layout>
        <FloatButton onClick={onUpload} icon={<UploadOutlined />}/>
      </div> 
    </GlobalContext.Provider>
  );
}

export default HomeView;
