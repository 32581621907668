import { EditOutlined, EllipsisOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Layout, Skeleton, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { GlobalContext } from '../../contexts/GlobalContext';


function Account() {
   
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isMobileDevice = useMediaQuery({ maxWidth: 767 })
  const isTabletDevice = useMediaQuery({minWidth: 768, maxWidth: 991});
  const { 
    keycloak,
    user
  } = useContext(GlobalContext);

  const card_actions = [
    <SettingOutlined disabled={keycloak?.authenticated} key="setting" />,
    <LogoutOutlined disabled={keycloak?.authenticated} key="ellipsis" onClick={() => keycloak.logout()}/>
  ];

  return (
    <Layout className='account'>
      <Card
        className='account-card'
        actions={card_actions}
      >
        <Meta
          avatar={
            keycloak?.authenticated ?
              <Avatar className='account-avatar'>
                {user.name[0] + user.family_name[0]}
              </Avatar>
            :
              <Avatar icon={<UserOutlined />} />
          }
          title={
            <Typography.Text>
              {keycloak?.authenticated ? user.name : "Anonymous User"}
            </Typography.Text>
          }
          description={keycloak?.authenticated ? user.email : ""}
        />
      </Card>
    </Layout>
  );
}

export default Account;