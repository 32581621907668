import { Button, Divider, Dropdown, FloatButton, Layout, Space, Typography } from 'antd';
import Logo from '../logo/Logo';
import DocumentsList from '../documents-list/DocumentsList';
import { ArrowLeftOutlined, DownOutlined, EditOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Account from '../account/Account';

const {Sider, Header, Footer } = Layout;

function LeftSidebar() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isMobileDevice = useMediaQuery({ maxWidth: 767 })
  const isTabletDevice = useMediaQuery({minWidth: 768, maxWidth: 991});



  /*TODO - These were supposed to be a smart toggle for the sider
  // when the screen is not desktop, collapse it, unless user clicks on the custom trigger.

  const [toggle_menu, setToggleMenu] = useState(false);
  const [menu_collapse, setMenuCollapse] = useState(toggle_menu || !isDesktopOrLaptop);

  const toggleMenu = () => {
    setToggleMenu(!toggle_menu);
    setMenuCollapse(!toggle_menu);
  };
  */

  return (
    <Sider 
      collapsed={isMobileDevice}
      collapsible
      collapsedWidth={0}
      trigger={null}
      width={"25vw"}
      className='sider'>
        <DocumentsList />
        <Account />

        {/*<FloatButton 
          icon={toggle_menu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} 
          onClick={toggleMenu} 
          className='trigger'
        />*/}
    </Sider>
  );
}

export default LeftSidebar;