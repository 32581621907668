import axios from 'axios';

const API = "https://llm.agcurate.com/"
//const API = "http://192.168.68.120:5601/"

//const API = "http://localhost:5601"

const resp = {
  "data": {
      "sources": [
          {
              "doc_id": "Landscaping-the-Agritech-Ecosystem-for-Smallholder-Farmers-in-Latin-America-and-the-Caribbean.pdf",
              "end": 2654,
              "similarity": 0.83,
              "start": 1077,
              "text": "page_label: 11\nfile_name: Landscaping-the-Agritech-Ecosystem-for-Smallholder-Farmers-in-Latin-America-and-the-Caribbean.pdf\n\n\nthe rise of agritech innovation hubs in the Southern \nCone,13 particularly in Brazil. Embrapa, Brazil’s \nAgricultural Research Corporation, a division of \nthe Ministry of Agriculture, has been a key driver in \nBrazil’s transformation into a leading global exporter \nof agricultural products. The city of Piracicaba in \nthe State of São Paulo is known as Latin America’s \nSilicon Valley of agriculture and is home to the Agtech \nGarage, which brings together 350 agritech start-ups \nunder the guidance of several of the largest agriculture \necosystem players in the world. \n10  Ibid.; World Bank Data (2019), “ GDP per capita, PPP (current international $) – GhanaGDP per capita, PPP (current international $) – Ghana ”. \n11  Ibid.; World Bank Data (2019), “ GDP per capita, PPP (current international $) – NicaraguaGDP per capita, PPP (current international $) – Nicaragua .” \n12  Ibid.; World Bank Data (2019), “ GDP per capita, PPP (current international $) – Kenya”.GDP per capita, PPP (current international $) – Kenya”.\n13  The Southern Cone region includes Argentina, Brazil, Chile, Paraguay and Uruguay. \n14  Wayra, which is part of Telefónica’s Connected Open Innovation area, helps to link technological disrupters with Telefónica to generate joint business opportunities. Wayra \noperates seven hubs in Europe and Latin America that reach the entrepreneurial ecosystems of 10 countries, including Argentina, Brazil, Chile, Colombia, Germany, Mexico, \nPeru, Spain, the United Kingdom and Venezuela. One of Wayra’s key objectives is to help local entrepreneurs in these"
          },
          {
              "doc_id": "Landscaping-the-Agritech-Ecosystem-for-Smallholder-Farmers-in-Latin-America-and-the-Caribbean.pdf",
              "end": 3790,
              "similarity": 0.82,
              "start": 2864,
              "text": "page_label: 39\nfile_name: Landscaping-the-Agritech-Ecosystem-for-Smallholder-Farmers-in-Latin-America-and-the-Caribbean.pdf\n\ntools have not reached the same scale as \nin Asia and Africa.\nThe AgriTech team identified very few digital agriculture \ntools with active user figures above 25,000.82 Most of \nthe tools profiled in this study have between 1,000 and \n5,000 active users with only a few agritechs targeting \nsmallholders, such as Colombia’s Control Ganadero \n(77,000 farmers) Comproagro (26,000 farmers) and \nMexico’s Smattcom (25,000 farmers) breaking the \n10,000 user mark. In Asia and Africa, by contrast, some services have scaled into the millions of users, although \nit should be noted that these regions have addressable \nmarkets four to six times larger than the Latin American \nmarket. Most tools in Latin America continue to be \nmanaged by cooperatives or NGOs with limited user \nnumbers, and there have been fewer impact investors \nhelping to scale commercial digital agriculture solutions \nin Latin America than in Asia and Africa.\n"
          }
      ],
      "text": "\nThe status of agritech in Brazil is strong. Brazil is a leading global exporter of agricultural products and is home to the Agtech Garage, which brings together 350 agritech start-ups. Embrapa, Brazil's Agricultural Research Corporation, has been a key driver in Brazil's transformation into a leading global exporter of agricultural products. Additionally, Wayra, which is part of Telefónica's Connected Open Innovation area, operates seven hubs in Europe and Latin America that reach the entrepreneurial ecosystems of 10 countries, including Brazil."
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
      "content-length": "3822",
      "content-type": "application/json"
  },
  "config": {
      "transitional": {
          "silentJSONParsing": true,
          "forcedJSONParsing": true,
          "clarifyTimeoutError": false
      },
      "adapter": [
          "xhr",
          "http"
      ],
      "transformRequest": [
          null
      ],
      "transformResponse": [
          null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "env": {},
      "headers": {
          "Accept": "application/json, text/plain, */*"
      },
      "mode": "cors",
      "method": "get",
      "url": "https://llm.agcurate.com//query?text=status of agritech in brazil"
  },
  "request": {}
};

const LLMAPI = {
  
  getDocuments: async () => {
    try {
      const URL = API + '/getDocuments';
      const response = await axios.get(`${URL}`, {mode: "cors"});
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  query: async (query) => {
    try {
      const URL = API + '/query?text='+query;
      const response = await axios.get(`${URL}`, {mode: "cors"}); //resp;
      console.log("response: ", response)
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};

export default LLMAPI;