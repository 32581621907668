import ReactGA from 'react-ga4'

const GA_Methods = {
  initialize: () => {
    ReactGA.initialize([{
      trackingId: 'G-RH751EMCDS',
      debug: true,
      gaOptions: {
        cookieDomain: 'none'
      }
    }])
  },
  pageview: (page) => {
    ReactGA.send({ hitType: "pageview", page: page.path, title: page.title });
    //ReactGA.pageview(page)
  },
  trackUserLoggedIn: (user) => {
    ReactGA.event({
      category: 'user',
      action: 'Logged In',
      value: user
    })
  },
  trackCropListOpened: () => {
    ReactGA.event({ category: 'user', action: 'crop_list_opened'})
  },
  trackQuery: (query) => {
    ReactGA.event({category: 'query', action: 'query_searched', label: query});
  },
  trackPDFOpen: (pdf_name) => {
    ReactGA.event({category: 'pdf', action: 'open_pdf', label: pdf_name})
  },
  trackUploadFile: (label) => {
    ReactGA.event({category: 'pdf', action: 'upload_pdf', label: "Unauthorized"})
  }
}


export default GA_Methods;