import { Col, FloatButton, Layout, Row, Space, Typography } from 'antd';
import Logo from '../logo/Logo';
import { Textfit } from 'react-textfit';
import { useMediaQuery } from 'react-responsive'

const {Sider, Header, Footer } = Layout;

function MainHeader() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isTabletDevice = useMediaQuery({minWidth: 768, maxWidth: 991});

  return (
    <Header className='header'>
      <Row>
        <Col xs={12} sm={8} md={8} lg={4} xl={4} style={{padding: 10, textAlign: "center"}} >
          <Logo type="dark" brandmark={false} />
        </Col>
        { (isDesktopOrLaptop || isTabletDevice) && 
        <Col xs={16} sm={16} md={16} lg={20} xl={20} style={{textAlign: "center"}}>
          <Typography.Title
            level={isTabletDevice ? 2 : 1}
          >
            Agriculture and Climate Library
          </Typography.Title>
        </Col>
        }
      </Row>
    </Header>
  );
}

export default MainHeader;