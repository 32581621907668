import { Avatar, Button, Divider, Drawer, Dropdown, FloatButton, Layout, List, Space, Typography } from 'antd';
import Logo from '../logo/Logo';

import LLMAPI from '../../services/llmapi';
import { useEffect, useState } from 'react';
import { ArrowLeftOutlined, DownOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import Analytics from '../../analytics/analytics';
import { useMediaQuery } from 'react-responsive';
import PDFViewer from '../PDFViewer/PDFViewer';

const {Sider, Header, Footer } = Layout;

const API = "https://llm.agcurate.com/"

function DocumentsList() {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 992 })
  const isTabletDevice = useMediaQuery({minWidth: 768, maxWidth: 991});

  const [documents, setDocuments] = useState([]);
  const [document_view, setDocumentView] = useState(false);
  const [filtered_documents, setFilteredDocuments] = useState([]);
    
  const [open, setOpen] = useState(false);
  const [PDF_opt, setPDFOpt] = useState("");

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const _documents = await LLMAPI.getDocuments();
      console.log("DOCUMENT: ", _documents);
      setDocuments(_documents);
      setFilteredDocuments(_documents);
    }

    fetchDocuments();
  }, [])

  const onSearch = (e) => {
    if (!e) return;
    const value =  e.target.value
    const filtered = documents.filter((item) =>
    item.id.toLowerCase().includes(value.toLowerCase()) || item.text.path.toLowerCase().includes(value.toLowerCase())
    );
    //setSearchText(value); //TODO - delete this maybe?
    setFilteredDocuments(filtered);
  };  

  const handleGoBack = () => {
    // Handle back button click here
    //setFieldDrawUIEnabled(false);
    //setGlobalFieldDrawUIEnabled(false);
  };

  const onPdfClick = (pdf) => {
    //TODO - open drawer to show the pdf
    const pdf_url = `${API}pdfs/${pdf.text.path.split("documents")[1]}`
    setOpen(!open);
    setPDFOpt({url: pdf_url, name: pdf.id})
    Analytics.trackPDFOpen(pdf.id); // send analytics event
  }

  return (
    <>
      <div className='documents-list'>
        <Layout className='documents-list-header'>
          <Divider orientation="left" style={{color: "#282c34"}}>
          {
            document_view  // if field draw is enabled, show a dummy field on top of fields list with editable title and description
            ?
            <Typography.Title level={5}>
              <Space style={{left: 0, cursor: "pointer"}} onClick={handleGoBack}>
                  <ArrowLeftOutlined />
                  Back to Fields
              </Space>
            </Typography.Title>
            :
            <Typography.Text level={5}>
              Documents ({filtered_documents?.length})
            </Typography.Text>
          }
          </Divider>
          <Search disabled={document_view} placeholder="Search documents" allowClear onChange={onSearch} style={{ width: "100%" }} />
        </Layout>
        <Layout style={{ backgroundColor: "#ffffff", overflow: "auto", overflowY: "scroll", overflowX: "hidden", flex: 0.9}} >
          <List
            dataSource={filtered_documents}
            renderItem={(item, index) => (
              <List.Item 
                style={{padding: 20}} 
                className='documents-list-item'
                onClick={() => onPdfClick(item)}
              >
                <List.Item.Meta
                  avatar={<FilePdfOutlined />}
                  title={item.id}
                  description={
                    isDesktopOrLaptop &&
                    <Typography.Paragraph ellipsis>

                      {item.text.text}
                    </Typography.Paragraph>
                  }
                />
              </List.Item>
            )}
            >
              
          </List>
        </Layout>
      </div>
      <Drawer
        className="drawer-full-page" 
        title={PDF_opt.name}
        placement="right" 
        rootStyle={{zIndex: 1010, bottom: 0,  top: "11vh", position: "absolute"}} 
        onClose={onClose} 
        size='large' 
        open={open}
      >
        <PDFViewer url={PDF_opt.url}/>
      </Drawer>
    </>
  );
}

export default DocumentsList;