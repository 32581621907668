// Modules
import React, { useRef, useState } from 'react'
import { Position, Viewer, Worker, Button, Tooltip, PrimaryButton } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { highlightPlugin, HighlightArea, Trigger, MessageIcon, RenderHighlightTargetProps,
  RenderHighlightContentProps,
  RenderHighlightsProps,} from '@react-pdf-viewer/highlight';


// Import styles
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import "@react-pdf-viewer/core/lib/styles/index.css";
//import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Style
import './PDFViewer.less'
import packageJson from '../../../package.json'; // With correct path


// Create new plugin instance
/* FIXME - This plugin should work to show toolbar UI for the pdf that's going to be opened */
//const defaultLayoutPluginInstance = defaultLayoutPlugin();

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface Note {
  id: number;
  content: string;
  highlightAreas: HighlightArea[];
  quote: string;
}

interface HighlightExampleProps {
  fileUrl: string;
}

// Component: PDFViewer
function PDFViewer (props) {
  
  const worker_version = packageJson.dependencies['pdfjs-dist'].replace(/^\^/, '');

  const [message, setMessage] = useState("");
  const [notes, setNotes] = useState([]);
  const notesContainerRef = useRef(null);
  let noteId = notes.length;

  const noteEles  = new Map();
  const [currentDoc, setCurrentDoc] = useState(null);

  const handleDocumentLoad = (e ) => {
      setCurrentDoc(e.doc);
      if (currentDoc && currentDoc !== e.doc) {
          // User opens new document
          setNotes([]);
      }
  };

  const renderHighlightTarget = (props) => (
      <div
        style={{
          background: '#eee',
          display: 'flex',
          position: 'absolute',
          left: `${props.selectionRegion.left}%`,
          top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
          transform: 'translate(0, 8px)',
          zIndex: 1,
        }}
      >
          <Tooltip
              position={Position.TopCenter}
              target={
                  <Button onClick={props.toggle}>
                      <MessageIcon />
                  </Button>
              }
              content={() => <div style={{ width: '100px' }}>Add a note</div>}
              offset={{ left: 0, top: -8 }}
          />
      </div>
  );

  const renderHighlightContent = (props ) => {
      const addNote = () => {
          if (message !== '') {
              const note = {
                  id: ++noteId,
                  content: message,
                  highlightAreas: props.highlightAreas,
                  quote: props.selectedText,
              };
              setNotes(notes.concat([note]));
              props.cancel();
          }
      };

      return (
          <div
              style={{
                  background: '#fff',
                  border: '1px solid rgba(0, 0, 0, .3)',
                  borderRadius: '2px',
                  padding: '8px',
                  position: 'absolute',
                  left: `${props.selectionRegion.left}%`,
                  top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                  zIndex: 1,
              }}
          >
              <div>
                  <textarea
                      rows={3}
                      style={{
                          border: '1px solid rgba(0, 0, 0, .3)',
                      }}
                      onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
              </div>
              <div
                  style={{
                      display: 'flex',
                      marginTop: '8px',
                  }}
              >
                  <div style={{ marginRight: '8px' }}>
                      <PrimaryButton onClick={addNote}>Add</PrimaryButton>
                  </div>
                  <Button onClick={props.cancel}>Cancel</Button>
              </div>
          </div>
      );
  };

  const jumpToNote = (note: Note) => {
      activateTab(3);
      const notesContainer = notesContainerRef.current;
      if (noteEles.has(note.id) && notesContainer) {
          notesContainer.scrollTop = noteEles.get(note.id).getBoundingClientRect().top;
      }
  };

  const renderHighlights = (props: RenderHighlightsProps) => (
      <div>
          {notes.map((note) => (
              <React.Fragment key={note.id}>
                  {note.highlightAreas
                      .filter((area) => area.pageIndex === props.pageIndex)
                      .map((area, idx) => (
                          <div
                              key={idx}
                              style={Object.assign(
                                  {},
                                  {
                                      background: 'yellow',
                                      opacity: 0.4,
                                  },
                                  props.getCssProperties(area, props.rotation)
                              )}
                              onClick={() => jumpToNote(note)}
                          />
                      ))}
              </React.Fragment>
          ))}
      </div>
  );

  const highlightPluginInstance = highlightPlugin({
      renderHighlightTarget,
      renderHighlightContent,
      renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  React.useEffect(() => {
      return () => {
          noteEles.clear();
      };
  }, []);

  const sidebarNotes = (
      <div
          ref={notesContainerRef}
          style={{
              overflow: 'auto',
              width: '100%',
          }}
      >
          {notes.length === 0 && <div style={{ textAlign: 'center' }}>There is no note</div>}
          {notes.map((note) => {
              return (
                  <div
                      key={note.id}
                      style={{
                          borderBottom: '1px solid rgba(0, 0, 0, .3)',
                          cursor: 'pointer',
                          padding: '8px',
                      }}
                      onClick={() => jumpToHighlightArea(note.highlightAreas[0])}
                      ref={(ref): void => {
                          noteEles.set(note.id, ref);
                      }}
                  >
                      <blockquote
                          style={{
                              borderLeft: '2px solid rgba(0, 0, 0, 0.2)',
                              fontSize: '.75rem',
                              lineHeight: 1.5,
                              margin: '0 0 8px 0',
                              paddingLeft: '8px',
                              textAlign: 'justify',
                          }}
                      >
                          {note.quote}
                      </blockquote>
                      {note.content}
                  </div>
              );
          })}
      </div>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
      sidebarTabs: (defaultTabs) =>
          defaultTabs.concat({
              content: sidebarNotes,
              icon: <MessageIcon />,
              title: 'Notes',
          }),
  });
  const { activateTab } = defaultLayoutPluginInstance;

  // Render
  return (
    <>
    {/*<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">*/}
    
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${worker_version}/build/pdf.worker.min.js`}>
      <div
        style={{
          height: "750px",
          maxWidth: "900px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Viewer
          fileUrl={props.url}
          plugins={[
            //defaultLayoutPluginInstance, //FIXME - See above in the declaration of the plugin 
            highlightPluginInstance
          ]}
        />
      </div>
    </Worker>
    </>
  )
}

// Export
export default PDFViewer;
