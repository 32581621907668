import { Button, Collapse, Divider, Drawer, FloatButton, Input, Layout, Modal, Space, Spin, Typography } from 'antd';
import Logo from '../logo/Logo';
import Search from 'antd/es/input/Search';
import { SearchOutlined } from '@ant-design/icons';
import DocumentsList from '../documents-list/DocumentsList';
import { useContext, useEffect, useState } from 'react';
import LLMAPI from '../../services/llmapi';
import Analytics from '../../analytics/analytics';
import { GlobalContext } from '../../contexts/GlobalContext';
import PDFViewer from '../PDFViewer/PDFViewer';

const {Sider, Header, Footer, Content } = Layout;
const API = "https://llm.agcurate.com/"

function MainZone() {

  const [query, setQuery] = useState(null);
  const [query_response, setQueryResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [PDF_opt, setPDFOpt] = useState("");

 // const [local_keycloak, setLocalKeycloak] = useState(null);

  const { 
    keycloak,
  } = useContext(GlobalContext);

  useEffect(() => {
    const fetchQuery = async () => {
      const _query_response = await LLMAPI.query(query);
      const _documents = await LLMAPI.getDocuments(); //FIXME - this should not be like this fix it ASAP!!!! FIXME
      const sources = _query_response.sources.map((source)=> {
        const doc_id = source.doc_id;
        const doc_opt = _documents.filter((doc) => {
          return doc.id === doc_id;
        })

        source.doc_opt = doc_opt[0];
        source.pdf_page = source.text.split("\n")[0].split(" ")[1]
        source.text = source.text.split("\n").splice(2)
        return source
      })
      _query_response.sources = sources;
      setLoading(false)
      setQueryResponse(_query_response)
    }

    if(query && keycloak.authenticated) {
      fetchQuery()
    }
    
  }, [query])


  const onSearch = (e) => {
    setLoading(true)
    setQueryResponse(null);
    
    if (e.target.value === "") {
      
      setQuery(null)
      setQueryResponse(null)
      setLoading(false);
      return;
    }
    const value = e.target.value
    setQuery(value); // set query in state
    Analytics.trackQuery(value); // send analytics event
  }

  const onChange = (e) => {    
    if (e.target.value === "") {
      setQuery(null)
      setQueryResponse(null)
      return;
    }
  }

  const onPdfClick = (source) => {
    //TODO - open drawer to show the pdf

    if(source.doc_opt) {
      console.log(source);
      const pdf_url = `${API}pdfs/${source.doc_opt.text.path.split("documents")[1]}`
      setOpen(!open);
      setPDFOpt({url: pdf_url, name: source.doc_opt.id})
      Analytics.trackPDFOpen(source.doc_opt.id); // send analytics event
    }
  }


  const onClose = () => {
    setOpen(false);
  };


  return (
    <Content 
      className='content'>
        {
          !keycloak?.authenticated
          ?
           <Layout className='login-overlay'>
              <div className='login-overlay-content'>
                <Typography.Text> 
                  Login to access the Agcurate GPT Library
                </Typography.Text>
                <div className='auth-buttons'>
                  <Space direction='horizontal' size="large">
                    <Button className="agci-button login-button" type='primary' onClick={() => keycloak?.login()}>
                      Login
                    </Button>
                    or
                    <Button className="agci-button register-button" type='primary' onClick={() => keycloak?.register()}>
                      Register
                    </Button>
                  </Space>
                </div>
              </div>  
           </Layout>
           :
           ""
        }
        <>
        {keycloak?.authenticated && console.log(keycloak?.idTokenParsed)}
        <div className="search-bar">
          <div className='search-bar-content'>
            <Divider />
            <Input 
              style={{
                fontSize: 20,
              }}
              onPressEnter={onSearch}
              onChange={onChange}
              placeholder='Search in Library, i.e. Kenya Insurance Statistics 2012' 
              bordered={false} 
              allowClear={true} 
              suffix={<SearchOutlined />} />
            <Divider />
          </div>
        </div>
        <Layout className="result-content">
          { loading ?
            <Spin />
            :
          
          <Layout className="query-result">
            <Space direction="vertical">
              {
                query_response && 
                <Collapse
                  collapsible="header"
                  defaultActiveKey={['1']}
                  items={[
                    {
                      key: '1',
                      label: "Response",
                      children: <p>{query_response.text}</p>,
                    },
                  ]}
                />
              }
              { query_response && query_response.sources.map((source) => (
                <Collapse
                  collapsible="icon"
                  defaultActiveKey={['1']}
                  items={[
                    {
                      key: '1',
                      label: <Typography.Text onClick={() => onPdfClick(source)}>{source.doc_id}</Typography.Text>,
                      children: source.text.map(str => <p>{str}</p>),
                    },
                  ]}
                />
              )
              )}
              
            </Space>
            <div className="shadow"></div>
          </Layout>
          }
        </Layout>
        <Drawer
        className="drawer-full-page" 
        title={PDF_opt.name}
        placement="right" 
        rootStyle={{zIndex: 1010, bottom: 0,  top: "11vh", position: "absolute"}} 
        onClose={onClose} 
        size='large' 
        open={open}
      >
        <PDFViewer url={PDF_opt.url}/>
      </Drawer>
        </>
    </Content>
  );
}

export default MainZone;