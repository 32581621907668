import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import HomeView from './views/Home/HomeView';
import reportWebVitals from './reportWebVitals';
import analytics from './analytics/analytics';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
analytics.init()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
        <Routes>
          {/* Login View */}
          <Route exact path="/" element={<HomeView />} />
        </Routes>
      </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
